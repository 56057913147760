<script setup lang="ts"></script>

<template>
  <div class="w-full h-screen relative">
    <Navbar />
    <slot />
  </div>
</template>

<style scoped></style>
